<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logos-sds-03.svg" width="142" alt="logo" /></a>
                    </div>
                    <p>O futuro da regulação de sinistros está aqui. Confie em quem entende.</p>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/SDS-Solu%C3%A7%C3%B5es-Digitais-em-Sinistro-207750943443832" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/sds_solucoes/" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="https://www.linkedin.com/company/sds-sinistro/" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/servicos">Serviços</a></li>
                        <li><a routerLink="/carreira">Carreira</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contato</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+5511917670722"><i class="bx bx-phone"></i>(11) 9 1767-0722</a></li>
                        <li><a href="mailto:contato&#64;sdssolucoes.com.br"><i class="bx bxs-envelope"></i> contato&#64;sdssolucoes.com.br</a></li>
                        <li><a href="https://maps.app.goo.gl/hMcLaAKXDdX1SYZw7" target="_blank"><i class="bx bxs-map"></i> R. Augusta, 1600 - 10º andar - Consolação, São Paulo - SP | CEP: 01314-010</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Inicio</a></li>
                        <li><a routerLink="/sobre">Sobre</a></li>
                        <li><a routerLink="/cobertura">Cobertura</a></li>
                        <li><a routerLink="/servicos">Serviços</a></li>
                        <li><a routerLink="/tecnologias">Tecnologias</a></li>
                        <li><a routerLink="/numeros">Números</a></li>
                        <li><a routerLink="/contatos">Contatos</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>SDS © 2024. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>